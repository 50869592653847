import {IS_SERVER} from "./constants.js";

if (!IS_SERVER) {
    // Set uploadcare locale
    window.UPLOADCARE_LOCALE_TRANSLATIONS = {
        buttons: {
            choose: {
                files: {
                    other: "Add Files",
                },
            },
        },
        dialog: {
            tabs: {
                names: {
                    files: "All Files",
                },
                file: {
                    drag: "Drag & drop any files",
                    button: "Choose file",
                },
                preview: {
                    multiple: {
                        title: "Selected %files%.",
                    },

                    // Messages for dialog error page
                    error: {
                        fileMinimalSize: {
                            title: "Title.",
                            text: "Text.",
                            back: "Back",
                        },
                        fileMaximumSize: {
                            title: "Title.",
                            text: "Text.",
                            back: "Back",
                        },
                    },
                },
            },
        },

        // messages for widget
        errors: {
            imagesOnly: "Only images allowed.",
            videosOnly: "Only videos allowed.",
            zipArchivesOnly: "Only zip archives allowed.",
            fileMinimalSize: "File is too small.",
            fileMaximumSize: "File is too large.",
            storageSpaceExhausted: "You have exhausted your storage space.",
        },
    };
}

Object.defineProperty(String.prototype, "capitalize", {
    value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false,
});

Element.prototype.parents = function (selector) {
    let elem = this;
    const elements = [];
    const hasSelector = selector !== undefined;

    while ((elem = elem.parentNode) !== null) {
        if (elem.nodeType !== Node.ELEMENT_NODE) continue;

        if (!hasSelector || elem.matches(selector)) elements.push(elem);
    }

    return elements;
};

Array.prototype.unique = function () {
    return Array.from(new Set(this));
};
